import css from 'styled-jsx/css'

export const LoginStyle = css`
  .login-page {
    min-height: 100vh;
    position: relative;
    background-color: #f6f8fc !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 80px 0 10px 0;
  }
  .message-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 16px;
    background: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin: 34px 0;
  }
  .message-text {
    margin-left: 10px;
  }

  .btn-resend_email {
    border-color: #fff;
    color: #1890ff;
    box-shadow: unset;
    text-decoration: underline;
  }

  .login-form-wrapper {
    width: 100%;
    height: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
  .header-logo-img {
    height: 52px;
  }

  .login-form {
    padding: 40px 24px 24px 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    background-color: #fff;
  }
  .title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 600;
  }

  .sub-title {
    color: #262626;
    text-align: center;
  }

  .link-forgot-pw {
    font-size: 16px;
    line-height: 20px;
    color: #1890ff;
    text-decoration: none;
    font-weight: 500;
    margin-bottom: 40px;
    display: block;
  }

  .divider {
    border-top-color: #bfbfbf;
  }

  .btn-google,
  .btn-facebook {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
  }
  .icon {
    color: #fff;
    margin-top: 2px;
  }

  .icon-facebook {
    margin-right: 8px;
    display: flex;
  }

  .btn-google {
    background-color: #ea4335;
    border-color: #ea4335;
  }

  .btn-facebook {
    background-color: #4267b2;
    border-color: #4267b2;
  }

  .text-bottom {
    color: #262626;
    text-align: center;
    margin-bottom: 0;
  }
  .link-bottom {
    text-decoration: underline;
    color: #1890ff;
    font-weight: 600;
    margin-left: 8px;
  }

  .profile-drop-down-link {
    display: flex;
    align-items: center;
    color: #262626;
    font-weight: bold;
  }
  img.lang-flag {
    height: 20px;
    margin-right: 5px;
  }

  .error-text {
    color: #ea4335;
    margin-bottom: 20px;
  }

  .btn-submit {
    width: 100%;
    border-radius: 5px;
  }

  .mb-2 {
    margin-bottom: 24px !important;
  }

  @media screen and (max-width: 575.98px) {
    .login-page {
      background-color: #fff !important;
    }
    .login-form {
      padding: 0;
      border-radius: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
`

export const LoginGlobalStyle = css.global`
  .ant-layout .ant-btn.btn-google .icon,
  .ant-layout .ant-btn.btn-google .icon {
    color: #fff;
    margin-top: 2px;
  }
  .wrapper-container {
    max-width: 1440px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    .wrapper-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
