import { FC } from 'react'
import React from 'react'
import { useTranslation } from '../hooks/UserTranslation'
import { Col, Row } from '../components/share/ANTD/Grid'

const Footer: FC = () => {
  const { translate } = useTranslation()
  return (
    <Row justify="center" className="w-100 mt-3">
      <style jsx>{`
        .footer-link {
          color: #000000;
          margin: 0 8px;
          font-weight: bold;
        }
      `}</style>
      <Col xs={24} md={16} lg={12} xl={8}>
        <div className="footer d-flex align-items-center justify-content-center flex-wrap">
          <div className="footer-item mr-2">{translate('footer:copyright')}</div>
        </div>
      </Col>
    </Row>
  )
}
export default Footer
