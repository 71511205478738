/* eslint-disable no-console */
import { useStoreTokenAndFetchUser } from '@core/hooks/CurrentUserHooks'
import { useAppRouter } from '@core/hooks/UseAppRouter'
import { useTranslation } from '@core/hooks/UserTranslation'
import { handleErrorAPIFromRequest, LIST_URL } from '@core/utilities/CatchApiError'
import { openNotificationWithIcon } from '@core/utilities/Notification'
import React, { ReactElement, useState } from 'react'
import { submitLoginAPI, submitResendEmailAPI } from 'src/service/api/login.api'
import { useAuthenData } from '../../store/AuthData'
import { toStringFromQuery } from '../../utilities/common'
import { LINK_URL_ADMIN_REVIEW_JOBS, LINK_URL_TOKEN_NOT_FOUND } from '../../utilities/LinkURL'
import LoginView from './components/LoginView'

const LoginWrapper = () => {
  const { verifyMessage, setVerifyMessage, setVerifyData, verifyData } = useAuthenData()
  const { translate } = useTranslation()
  const router = useAppRouter()

  const [nonFieldError, setNoneFieldError] = useState<string | ReactElement>()
  const [isVerify, setIsVerify] = useState(false)
  const [loginIn, setLoginIn] = useState(false)

  const message = verifyMessage.message
  const fallBackUrl = router.query.redirect
  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()

  /* ----------- API ----------- */

  const onFinish = (values) => {
    setLoginIn(true)

    return submitLoginAPI(values)
      .then((response) => {
        storeTokenAndFetchUser(response.data.token).then(() => {
          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_ADMIN_REVIEW_JOBS)
        })
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          router,
          page: LIST_URL.LOGIN,
          callbackAction: (errorText) => setNoneFieldError(errorText),
          callbackType: (type) => {
            if (['NOT_VERIFIED_ACCOUNT', 'NOT_VERIFIED_USER'].includes(type)) {
              const response = error?.response || {}
              const { data } = response
              setVerifyMessage({ message: translate('notification:you_have_not_verified_yet') })
              setVerifyData({
                email: values.email.toLowerCase(),
                user_id: data?.messages?.user_id
              })
              setIsVerify(true)
              localStorage.setItem('AUTHENTICATION_TOKEN', '')
            }
          }
        })
      })
      .finally(() => {
        setLoginIn(false)
      })
  }

  const onSendVerify = (email, user_id) => {
    return submitResendEmailAPI({ email, user_id })
      .then((response) => {
        if (response.status && response.status !== 200) {
          setIsVerify(false)
          setVerifyMessage({
            message: translate('notification:you_have_verified_your_email')
          })
          router.push('/login')
        }
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          page: LIST_URL.REGISTER_RESEND,
          callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
          callbackType: (type) => {
            if (type === 'INVALID_TOKEN') {
              router.push(LINK_URL_TOKEN_NOT_FOUND)
            }
          }
        })
        throw error
      })
  }

  /* ----------- RENDER ----------- */

  return (
    <LoginView
      verifyData={verifyData}
      nonFieldError={nonFieldError}
      isVerify={isVerify}
      loginIn={loginIn}
      message={message}
      onFinish={onFinish}
      onSendVerify={onSendVerify}
    />
  )
}

export default LoginWrapper
